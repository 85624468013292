import React from 'react';
import { Link } from 'react-router-dom';
import '../css/sidebar.css';
import LogoWebsite from '../elements/logo-website.png';
import HamburgerIcon from '../elements/hamburger-icon.png';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.blackShadowRef = React.createRef();
    this.menuListRef = React.createRef();
  }

  menuOpenController() {
    this.blackShadowRef.current.style.display = 'block';
    this.menuListRef.current.style.display = 'block';
  }

  menuCloseController() {
    this.blackShadowRef.current.style.display = 'none';
    this.menuListRef.current.style.display = 'none';
  }

  render() {
    return (
      <div>
        <div className='navBarClass'>
          <div className='logoWrapper'>
            <img src={LogoWebsite} alt='Poland Lotteries Logo' width='200px' />
          </div>

          <div className='navWrapper'>
            <Link to='/home' className='navItem menuWithBg'>
              Home
            </Link>

            <Link to='/result/monday' className='navItem menuWithBg'>
              Monday
            </Link>

            <Link to='/result/tuesday' className='navItem menuWithBg'>
              Tuesday
            </Link>

            <Link to='/result/wednesday' className='navItem menuWithBg'>
              Wednesday
            </Link>

            <Link to='/result/thursday' className='navItem menuWithBg'>
              Thursday
            </Link>

            <Link to='/result/friday' className='navItem menuWithBg'>
              Friday
            </Link>

            <Link to='/result/saturday' className='navItem menuWithBg'>
              Saturday
            </Link>

            <Link to='/result/sunday' className='navItem menuWithBg'>
              Sunday
            </Link>

            <Link to='/live-draw' className='navItem menuWithBg'>
              Live Draw
            </Link>
          </div>
        </div>

        <div className='navMobile'>
          <div className='blackShadow' ref={this.blackShadowRef} onClick={() => this.menuCloseController()}></div>

          <div className='logoWrapper'>
            <Link to='/home'>
              <img src={LogoWebsite} alt='Poland Lotteries Logo' width='200px' />
            </Link>
          </div>

          <div onClick={() => this.menuOpenController()} className='hamburgerIcon'>
            <img src={HamburgerIcon} alt='Hamburger' />
          </div>

          <div className='menuList' ref={this.menuListRef}>
            <Link to='/home' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Home</div>
            </Link>

            <Link to='/result/monday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Monday</div>
            </Link>

            <Link to='/result/tuesday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Tuesday</div>
            </Link>

            <Link to='/result/wednesday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Wednesday</div>
            </Link>

            <Link to='/result/thursday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Thursday</div>
            </Link>

            <Link to='/result/friday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Friday</div>
            </Link>

            <Link to='/result/saturday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Saturday</div>
            </Link>

            <Link to='/result/sunday' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Sunday</div>
            </Link>

            <Link to='/live-draw' className='menuItem'>
              <div onClick={() => this.menuCloseController()}>Live Draw</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
