import React from 'react';
import { withRouter } from 'react-router-dom';
import helperFunction from './helperFunction';
import Sidebar from './components/sidebar';
import './css/resultByDay.css';
import HelperFunction from './helperFunction';
import Footer from './components/footer';
import FooterMenu from './components/footerMenu';

class ResultByDay extends React.Component {
  constructor(props) {
    super(props);

    document.title = `${helperFunction.uppercaseFirst(
      this.props.match.params.day.toString()
    )} Results | Poland Lotteries`;

    this.state = {
      resultByDayData: [],
      paramsChecker: '',
      pageNumber: 1,
      maxPageNumber: 1,
    };

    this.getData();
  }

  getData() {
    const theDay = this.props.match.params.day;

    if (theDay !== 'all') {
      fetch(`/api/getResultByDay?day=${theDay}&pageNumber=${this.state.pageNumber}`).then(response =>
        response.json().then(res => {
          this.setState({
            resultByDayData: res.resultResponse,
            paramsChecker: this.props.match.params.day,
            maxPageNumber: res.maxPageNumber,
          });
        })
      );
    } else if (theDay === 'all') {
      fetch(`/api/results?pageNumber=${this.state.pageNumber}`).then(response =>
        response.json().then(res => {
          let resultByDayData = [];

          for (let i = 0; i < res.rows.length; i++) {
            resultByDayData.push(res.rows[i]);
          }

          this.setState({
            resultByDayData,
            paramsChecker: 'all',
            maxPageNumber: res.maxPageNumber,
          });
        })
      );
    }
  }

  componentWillUpdate(updatedComponent) {
    if (updatedComponent.match.params.day !== this.state.paramsChecker) {
      this.getData();
    }
  }

  increasePageNumber() {
    if (this.state.pageNumber < this.state.maxPageNumber) {
      let pageNumber = this.state.pageNumber;
      pageNumber++;

      this.setState({ pageNumber }, () => {
        this.getData();
      });
    }
  }

  decreasePageNumber() {
    if (this.state.pageNumber > 1) {
      let pageNumber = this.state.pageNumber;
      pageNumber--;

      this.setState({ pageNumber }, () => {
        this.getData();
      });
    }
  }

  render() {
    return (
      <div className='resultByDayClass'>
        <Sidebar />

        <div className='contentContainer'>
          <div className='titleByDayWrapper'>
            <div>{helperFunction.uppercaseFirst(this.props.match.params.day.toString())} Result</div>

            <div>
              <span onClick={this.decreasePageNumber.bind(this)} className='prevNextButton noselect'>
                Prev
              </span>

              <span className='dashLine'> - </span>

              <span onClick={this.increasePageNumber.bind(this)} className='prevNextButton noselect'>
                Next
              </span>
            </div>
          </div>

          <table>
            <tbody>
              <tr>
                <th>Day</th>
                <th>Date</th>
                <th>Result</th>
                <th className='mobileNone'>Prize</th>
              </tr>

              {this.state.resultByDayData.map((item, index) => (
                <tr key={index}>
                  <td>{HelperFunction.getHari(new Date(item.date).getDay())}</td>

                  <td>{`${new Date(item.date).getDate()} ${HelperFunction.getNamaBulan(
                    new Date(item.date).getMonth()
                  )} ${new Date(item.date).getFullYear()}`}</td>

                  <td>{HelperFunction.resultSpacer(item.first)}</td>
                  <td className='mobileNone'>1st Prize</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Footer />
        <FooterMenu />
      </div>
    );
  }
}

export default withRouter(ResultByDay);
