import React from 'react';
import { withRouter } from 'react-router-dom';
import './css/admin.css';
import HelperFunction from './helperFunction';

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resultFirst: '',
      resultSecond: '',
      historyData: [],
      sudahLive: null,
      buttonText: 'Mulai Live Draw',
    };

    document.title = `Admin | Poland Lotteries`;
    this.getData();
  }

  componentDidMount() {
    fetch('/api/getTodayResult').then(response => {
      response.json().then(res => {
        this.setState({ sudahLive: res.sudahLive });

        if (res.sudahLive) {
          this.setState({ buttonText: 'Result hari ini sudah live!' });

          const userConfirm = window.confirm(
            `Apakah Anda yakin nomor result yang Anda masukkan (${this.state.resultFirst}) sudah benar?`
          );

          if (userConfirm) {
            fetch('/api/goLive', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                resultFirst: this.state.resultFirst,
              }),
            }).then(response => {
              response.json().then(res => {
                alert(res.message);
                this.setState({
                  sudahLive: true,
                  buttonText: 'Result hari ini sudah live!',
                  resultFirst: '',
                });

                this.getData();
              });
            });
          }
        }
      });
    });
  }

  valueSetter(ev) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  goLiveSubmit() {
    if (this.state.sudahLive) {
      window.alert('Result hari ini sudah diupdate.');
    } else if (this.state.resultFirst.length !== 6) {
      window.alert('Input harus 6 angka.');
    } else {
      const userConfirm = window.confirm(
        `Apakah Anda yakin nomor result yang Anda masukkan (${this.state.resultFirst}) sudah benar?`
      );

      if (userConfirm) {
        fetch('/api/goLive', {
          method: 'POST',

          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            resultFirst: this.state.resultFirst,
            resultSecond: this.state.resultSecond,
            resultThird: this.state.resultThird,
          }),
        }).then(response => {
          response.json().then(res => {
            alert(res.message);

            this.setState({
              sudahLive: true,
              buttonText: 'Result hari ini sudah live!',
              resultFirst: '',
            });

            this.getData();
          });
        });
      }
    }
  }

  getData() {
    fetch(`/api/results?pageNumber=1`)
      .then(response => {
        response.json().then(res => {
          let historyData = [];

          for (let i = 0; i < res.rows.length; i++) {
            historyData.push(res.rows[i]);
          }

          this.setState({ historyData });
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  checkInput(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (/1|2|3|4|5|6|7|8|9|0/.test(keyValue) === false) {
      event.preventDefault();
    }
  }

  render() {
    return (
      <div className='adminClass'>
        <h1>Admin Page</h1>

        <div className='inputResult'>
          <input
            disabled={this.state.sudahLive}
            placeholder='Masukkan Result #1'
            onChange={this.valueSetter.bind(this)}
            type='text'
            name='resultFirst'
            maxLength='6'
            value={this.state.resultFirst}
            onKeyPress={this.checkInput.bind(this)}
          />

          <input
            disabled={this.state.sudahLive}
            placeholder='Masukkan Result #2'
            onChange={this.valueSetter.bind(this)}
            type='text'
            name='resultSecond'
            maxLength='6'
            value={this.state.resultSecond}
            onKeyPress={this.checkInput.bind(this)}
          />

          <input
            disabled={this.state.sudahLive}
            placeholder='Masukkan Result #3'
            onChange={this.valueSetter.bind(this)}
            type='text'
            name='resultThird'
            maxLength='6'
            value={this.state.resultThird}
            onKeyPress={this.checkInput.bind(this)}
          />

          <div className='goLiveButton' onClick={this.goLiveSubmit.bind(this)}>
            {this.state.buttonText}
          </div>
        </div>

        <table>
          <tbody>
            <tr>
              <th>Day</th>
              <th>Date</th>
              <th>Result</th>
            </tr>

            {this.state.historyData.map((item, index) => (
              <tr key={index}>
                <td>{HelperFunction.getHari(new Date(item.date).getDay())}</td>

                <td>{`${new Date(item.date).getDate()} ${HelperFunction.getNamaBulan(
                  new Date(item.date).getMonth()
                )} ${new Date(item.date).getFullYear()}`}</td>

                <td>{HelperFunction.resultSpacer(item.first)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(Admin);
