import React from 'react';
import './css/home.css';
import { withRouter } from 'react-router-dom';
import Sidebar from './components/sidebar';
import HelperFunction from './helperFunction';
import { Link } from 'react-router-dom';
import Footer from './components/footer';
import FooterMenu from './components/footerMenu';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      todayData: {},
      historyData: [],
      tommorow: '',
      untilTommorow: '',
    };

    this.getData();
    document.title = 'Home | Poland Lotteries';
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 1000);
    this.getTommorowTime();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  timer() {
    const sekarang = new Date().getTime();
    const sisaWaktu = this.state.tommorow - sekarang;
    this.setState({ untilTommorow: sisaWaktu });
  }

  getTommorowTime() {
    const tommorowTime = new Date();
    tommorowTime.setDate(tommorowTime.getDate() + 1);
    tommorowTime.setHours(17, 15);
    this.setState({ tommorow: tommorowTime.getTime() });
  }

  getData() {
    fetch(`/api/results?pageNumber=1`)
      .then(response => {
        response.json().then(res => {
          this.setState({ todayData: res.rows[0] });
          let historyData = [];

          for (let i = 1; i <= 7; i++) {
            historyData.push(res.rows[i]);
          }

          this.setState({ historyData });
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  todayDate() {
    if (!this.state.todayData.date) {
      const tanggalHariIni = new Date(this.state.todayData.date);
      return `${HelperFunction.getHari(
        tanggalHariIni.getDay()
      )}, ${tanggalHariIni.getDate()} ${HelperFunction.getNamaBulan(
        tanggalHariIni.getMonth()
      )} ${tanggalHariIni.getFullYear()}`;
    }
  }

  getSisaWaktu() {
    return `${Math.floor((this.state.untilTommorow / 1000 / 60 / 60) % 24)} Hours ${Math.floor(
      (this.state.untilTommorow / 1000 / 60) % 60
    )} Minutes ${Math.floor((this.state.untilTommorow / 1000) % 60)} Seconds`;
  }

  convertToRedBall(angka, index) {
    return <img src={`/images/bola-${angka}-red.png`} alt='Bola Angka' key={index} className='bolaAngka' />;
  }

  convertToBlueBall(angka, index) {
    return <img src={`/images/bola-${angka}-blue.png`} alt='Bola Angka' key={index} className='bolaAngka' />;
  }

  render() {
    return (
      <div className='homeClass'>
        <Sidebar />

        <div className='titleWrapper'>
          <div className='leftSectionTitle'>
            <div className='bigTitle'>Latest Result</div>
            <div className='todayDate'>{this.todayDate()}</div>
          </div>

          <div className='rightSectionTitle'>
            <div className='nextResultSection titleNextResult'>Next Result In Approximately</div>
            <div className='nextResultSection'>{this.getSisaWaktu()}</div>
          </div>
        </div>

        <div className='contentWrapper'>
          <div className='leftSideTable'>
            <div className='firstSecondThirdResult'>
              <div className='firstResult'>
                <div className='firstTitle'>1st</div>

                <div className='firstResultNumber'>
                  {this.state.todayData.first !== undefined
                    ? this.state.todayData.first.split('').map((angka, index) => {
                        return this.convertToRedBall(angka, index);
                      })
                    : null}
                </div>

                <div className='firstTitle mobileNone'>1st</div>
              </div>

              <div className='secondAndThirdResult'>
                <div className='secondResult'>
                  <span className='secondAndThirdTitle'>2nd</span>

                  <span className='secondAndThirdResultNumber'>
                    {this.state.todayData.second !== undefined
                      ? this.state.todayData.second.split('').map((angka, index) => {
                          return this.convertToBlueBall(angka, index);
                        })
                      : null}
                  </span>
                </div>

                <div className='thirdResult'>
                  <span className='secondAndThirdTitle'>3rd</span>

                  <span className='secondAndThirdResultNumber'>
                    {this.state.todayData.third !== undefined
                      ? this.state.todayData.third.split('').map((angka, index) => {
                          return this.convertToBlueBall(angka, index);
                        })
                      : null}
                  </span>
                </div>
              </div>
            </div>

            <div className='specialAndConsolation'>
              <div className='specialResult'>
                <div className='specialResultTitle'>Special Prize</div>
                <div className='specialResultNumber'>{HelperFunction.resultSpacer(this.state.todayData.special1)}</div>
                <div className='specialResultNumber'>{HelperFunction.resultSpacer(this.state.todayData.special2)}</div>
                <div className='specialResultNumber'>{HelperFunction.resultSpacer(this.state.todayData.special3)}</div>
                <div className='specialResultNumber'>{HelperFunction.resultSpacer(this.state.todayData.special4)}</div>
                <div className='specialResultNumber'>{HelperFunction.resultSpacer(this.state.todayData.special5)}</div>
              </div>

              <div className='specialResult'>
                <div className='specialResultTitle'>Consolation Prize</div>

                <div className='specialResultNumber'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation1)}
                </div>

                <div className='specialResultNumber'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation2)}
                </div>

                <div className='specialResultNumber'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation3)}
                </div>

                <div className='specialResultNumber'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation4)}
                </div>

                <div className='specialResultNumber'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation5)}
                </div>
              </div>
            </div>
          </div>

          <div className='rightSideTable'>
            <div className='tableHeader'>
              <div className='tableTitle'>Past Result</div>

              <div className='seePrevious'>
                <Link
                  to='/result/all'
                  className='navItem'
                  style={{
                    color: 'var(--redPrimary)',
                    margin: 0,
                    textShadow: 'none',
                  }}
                >
                  See All Result
                </Link>
              </div>
            </div>

            <table>
              <tbody>
                <tr>
                  <th>Day</th>
                  <th>Date</th>
                  <th>Result</th>
                </tr>

                {this.state.historyData.map((item, index) => (
                  <tr key={index}>
                    <td>{HelperFunction.getHari(new Date(item.date).getDay())}</td>
                    <td>{`${new Date(item.date).getDate()} ${HelperFunction.getNamaBulan(
                      new Date(item.date).getMonth()
                    )} ${new Date(item.date).getFullYear()}`}</td>
                    <td>{HelperFunction.resultSpacer(item.first)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <Footer />
        <FooterMenu />
      </div>
    );
  }
}

export default withRouter(Home);
