import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from './components/sidebar';
import FooterMenu from './components/footerMenu';
import ResponsibleGamblingImg from './elements/responsible.jpg';

class responsibleGambling extends Component {
  constructor(props) {
    super(props);
    document.title = 'Responsible Gambling | Poland Lotteries';
  }

  render() {
    return (
      <div>
        <Sidebar />

        <div className='contentContainer containerOnDesktop extraPadding'>
          <div className='titleByDayWrapper paddingAdjusments'>Responsible Gambling</div>

          <div className='isiParagraf'>
            <img src={ResponsibleGamblingImg} alt='Responsible Gambling' />

            <h2>
              We want lots of people to play a little, rather than a few playing a lot – it's our guiding principle.
            </h2>

            <p>
              Around 60% of UK adults play our games so encouraging responsible play is at the heart of everything we
              do. The way we design our games and the tools we develop put you in control of your play.
            </p>
          </div>

          <div className='isiParagraf'>
            <h2>Help is at Hand</h2>

            <p>
              Gaming is fun but for a few, it may become a problem. If you're worried about yourself, or someone you
              know, speak in confidence to our Customer Services.
            </p>
          </div>

          <div className='isiParagraf'>
            <h2>What Type of Player Are You?</h2>

            <p>
              GamCare have an online test that helps you to consider certain behaviours to ensure that playing not just
              our games, but all the games you play, remain fun. A series of questions walk you through what specialists
              consider important when looking at responsible gaming.
            </p>
          </div>
        </div>

        <FooterMenu />
      </div>
    );
  }
}

export default withRouter(responsibleGambling);
