import React, { Component } from 'react';
import Sidebar from './components/sidebar';
import FooterMenu from './components/footerMenu';
import { withRouter } from 'react-router-dom';
import HelpImg from './elements/contact-us.jpg';

class contactUs extends Component {
  render() {
    return (
      <div>
        <Sidebar />

        <div className='contentContainer containerOnDesktop extraPadding'>
          <div className='titleByDayWrapper paddingAdjusment'>Contact Us</div>

          <div className='isiParagraf'>
            <img src={HelpImg} alt='Change Life' />

            <p>
              If you found any question to ask, please don't hesitate to contact us at{' '}
              <a href='mailto:cs@polandlotteries.com'>cs@polandlotteries.com</a>
            </p>
          </div>
        </div>

        <FooterMenu />
      </div>
    );
  }
}

export default withRouter(contactUs);
