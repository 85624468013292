import React from 'react';
import './css/routing.css';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Home from './home';
import ResultByDay from './resultByDay';
import LiveDraw from './liveDraw';
import Admin from './admin';
import Login from './login';
import AboutUs from './aboutUs';
import ResponsibleGambling from './responsibleGambling';
import ContactUs from './contactUs';
// import Announcement from './Announcement';

class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false, // UNTUK DEVELOPMENT PAKAI TRUE
      // showAnnouncement: true,
    };
  }

  // componentDidMount() {
  //   const lastShown = localStorage.getItem('announcementShown');

  //   if (lastShown - new Date().getTime() > -3600000) {
  //     this.setState({ showAnnouncement: false });
  //   }
  // }

  // handleCloseAnnouncement() {
  //   this.setState({ showAnnouncement: false });
  //   localStorage.setItem('announcementShown', new Date().getTime());
  // }

  render() {
    return (
      <div>
        {/* <div style={this.state.showAnnouncement ? { display: 'block' } : { display: 'none' }}>
          <Announcement
            closeAnnouncement={() => {
              this.handleCloseAnnouncement();
            }}
          />
        </div> */}

        <BrowserRouter>
          <div className='routingClass'>
            <Route path='/' exact render={() => <Redirect to='/home' />} />
            <Route path='/home' render={() => <Home />} />
            <Route path='/result/:day' render={() => <ResultByDay />} />
            <Route path='/live-draw' render={() => <LiveDraw />} />
            <Route path='/about-us' render={() => <AboutUs />} />
            <Route path='/responsible-gambling' render={() => <ResponsibleGambling />} />
            <Route path='/contact-us' render={() => <ContactUs />} />

            <Route
              path='/admin'
              render={() =>
                this.state.authorized ? <Admin /> : <Login isAuth={ev => this.setState({ authorized: ev })} />
              }
            />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default Routing;
