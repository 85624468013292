import React, { Component } from 'react';
import '../css/footerMenu.css';
import { Link } from 'react-router-dom';

class footerMenu extends Component {
  render() {
    return (
      <div className='footerMenuClass'>
        <div className='footerMenuWrapper'>
          <Link className='navItem' to='/about-us'>
            <div className='footerMenuClassItems footerMenuWithBg'>About Us</div>
          </Link>

          <Link className='navItem' to='/responsible-gambling'>
            <div className='footerMenuClassItems footerMenuWithBg'>Responsible Gambling</div>
          </Link>

          <Link className='navItem' to='/contact-us'>
            <div className='footerMenuClassItems footerMenuWithBg'>Contact Us</div>
          </Link>
        </div>
      </div>
    );
  }
}

export default footerMenu;
