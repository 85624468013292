import React from 'react';
import { withRouter } from 'react-router-dom';
import Sidebar from './components/sidebar';
import './css/liveDraw.css';
import HelperFunction from './helperFunction';
import FooterMenu from './components/footerMenu';

class LiveDraw extends React.Component {
  constructor(props) {
    super(props);
    document.title = `Live Draw | Poland Lotteries`;

    this.state = {
      todayData: {},
      historyData: [],
      tommorow: '',
      untilTommorow: '',
      sudahLive: null,
    };

    this.getDataEvery23();
  }

  componentDidMount() {
    this.intervalId = setInterval(this.timer.bind(this), 1000);
    this.getTommorowTime();
    this.intervalBaru = setInterval(this.getDataEvery23.bind(this), 23000); // Development = 1000 ; Build = 23000
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getDataEvery23() {
    fetch('/api/getTodayResult').then(response => {
      response.json().then(res => {
        if (res.sudahLive === true) {
          this.setState({
            todayData: res.todayData,
            sudahLive: res.sudahLive,
          });
        } else {
          clearInterval(this.intervalBaru);
          this.setState({ sudahLive: false });
        }
      });
    });
  }

  timer() {
    const nowTime = new Date().getTime();
    const sisaWaktu = this.state.tommorow - nowTime;
    this.setState({ untilTommorow: sisaWaktu });
  }

  getTommorowTime() {
    const tommorowTime = new Date();
    tommorowTime.setDate(tommorowTime.getDate() + 1);
    tommorowTime.setHours(21, 30);
    this.setState({ tommorow: tommorowTime.getTime() });
  }

  getSisaWaktu() {
    if (
      Math.floor(this.state.untilTommorow / 1000 / 60 / 60) % 24 < 24 &&
      (this.state.untilTommorow / 1000 / 60 / 60) % 24 > 22.5
    ) {
      return `Live Draw is Starting. If the result doesn't showing up shortly, please refresh your browser.`;
    } else {
      return (
        <div>
          <div className='liveDrawString'>Live Draw will start in approximately</div>

          <div>
            {Math.floor((this.state.untilTommorow / 1000 / 60 / 60) % 24)} Hours{' '}
            {Math.floor((this.state.untilTommorow / 1000 / 60) % 60)} Minutes{' '}
            {Math.floor((this.state.untilTommorow / 1000) % 60)} Seconds
          </div>
        </div>
      );
    }
  }

  defineTheDate() {
    const tanggalShowing = new Date();

    return `${tanggalShowing.getDate()} ${HelperFunction.getNamaBulan(
      tanggalShowing.getMonth()
    )} ${tanggalShowing.getFullYear()}`;
  }

  convertToRedBall(angka, index) {
    return <img src={`/images/bola-${angka}-red.png`} alt='Bola Angka' key={index} />;
  }

  convertToBlueBall(angka, index) {
    return <img src={`/images/bola-${angka}-blue.png`} alt='Bola Angka' key={index} />;
  }

  render() {
    return (
      <div className='liveDrawClass'>
        <Sidebar />

        <div className='liveDrawWrapper'>
          <div className='titleWrapper'>
            <div>
              <h1>Live Draw</h1>

              <div>
                Date: {this.defineTheDate()} | Draw No. #{this.state.todayData.drawNo}
              </div>
            </div>

            <div className='sisaWaktu'>{this.getSisaWaktu()}</div>
          </div>

          <div className='bodyWrapper'>
            <div className='firstRow'>
              <div className='firstSecondThirdLiveDraw liveDrawContainer'>
                <div className='resultGroupLive'>
                  <div className='titleLiveNumber'>1st Prize</div>

                  <div className='firstSecondThirdLiveNumber'>
                    {this.state.todayData.first !== undefined
                      ? this.state.todayData.first.split('').map((angka, index) => {
                          return this.convertToRedBall(angka, index);
                        })
                      : null}
                  </div>
                </div>

                <div className='resultGroupLive secondGroupLive'>
                  <div className='titleLiveNumber'>2nd Prize</div>

                  <div className='firstSecondThirdLiveNumber'>
                    {this.state.todayData.second !== undefined
                      ? this.state.todayData.second.split('').map((angka, index) => {
                          return this.convertToBlueBall(angka, index);
                        })
                      : null}
                  </div>
                </div>

                <div className='resultGroupLive'>
                  <div className='titleLiveNumber'>3rd Prize</div>

                  <div className='firstSecondThirdLiveNumber'>
                    {this.state.todayData.third !== undefined
                      ? this.state.todayData.third.split('').map((angka, index) => {
                          return this.convertToBlueBall(angka, index);
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>

            <div className='secondRow'>
              <div className='otherLiveDraw liveDrawContainer'>
                <div className='otherTitle'>Special Prize</div>
                <div className='otherNumberResult'>{HelperFunction.resultSpacer(this.state.todayData.special1)}</div>
                <div className='otherNumberResult'>{HelperFunction.resultSpacer(this.state.todayData.special2)}</div>
                <div className='otherNumberResult'>{HelperFunction.resultSpacer(this.state.todayData.special3)}</div>
                <div className='otherNumberResult'>{HelperFunction.resultSpacer(this.state.todayData.special4)}</div>
                <div className='otherNumberResult'>{HelperFunction.resultSpacer(this.state.todayData.special5)}</div>
              </div>

              <div className='otherLiveDraw liveDrawContainer'>
                <div className='otherTitle'>Consolation Prize</div>

                <div className='otherNumberResult'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation1)}
                </div>

                <div className='otherNumberResult'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation2)}
                </div>

                <div className='otherNumberResult'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation3)}
                </div>

                <div className='otherNumberResult'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation4)}
                </div>

                <div className='otherNumberResult'>
                  {HelperFunction.resultSpacer(this.state.todayData.consolation5)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterMenu />
      </div>
    );
  }
}

export default withRouter(LiveDraw);
