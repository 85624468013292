exports.uppercaseFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

exports.getHari = angka => {
  let namaHari = '';

  switch (angka) {
    case 0:
      namaHari = 'Sunday';
      break;

    case 1:
      namaHari = 'Monday';
      break;

    case 2:
      namaHari = 'Tuesday';
      break;

    case 3:
      namaHari = 'Wednesday';
      break;

    case 4:
      namaHari = 'Thursday';
      break;

    case 5:
      namaHari = 'Friday';
      break;

    case 6:
      namaHari = 'Saturday';
      break;
    // no default
  }

  return namaHari;
};

exports.getNamaBulan = angka => {
  let namaBulan = '';

  switch (angka) {
    case 0:
      namaBulan = 'January';
      break;

    case 1:
      namaBulan = 'February';
      break;

    case 2:
      namaBulan = 'March';
      break;

    case 3:
      namaBulan = 'April';
      break;

    case 4:
      namaBulan = 'May';
      break;

    case 5:
      namaBulan = 'June';
      break;

    case 6:
      namaBulan = 'July';
      break;

    case 7:
      namaBulan = 'August';
      break;

    case 8:
      namaBulan = 'September';
      break;

    case 9:
      namaBulan = 'October';
      break;

    case 10:
      namaBulan = 'November';
      break;

    case 11:
      namaBulan = 'December';
      break;
    // no default
  }

  return namaBulan;
};

exports.resultSpacer = result => {
  if (result !== undefined && result !== null) {
    let spacedResult = '';
    const parts = result.split('');

    for (let i = 0; i < 6; i++) {
      if (parts[i] === undefined) {
        spacedResult += '_ ';
      } else {
        spacedResult += parts[i] + ' ';
      }
    }

    return spacedResult.trim();
  }
};
