import React from 'react';
import '../css/footer.css';
import NumbersImg from '../elements/numbers.jpg';
import JackpotImg from '../elements/jackpot.jpg';
import Coins from '../elements/coins.jpg';
import MobileApps from '../elements/mobile-app.jpg';

class footer extends React.Component {
  render() {
    return (
      <div className='footerClass'>
        <div className='componentDiv'>
          <div className='footerImage'>
            <img src={NumbersImg} alt='Numbers' width='75px' height='75px' />
          </div>

          <div className='textPart'>
            <div className='titleFooterComponent'>What Numbers to Buy</div>
            <div className='descriptionFooterComponent'>Translate your inspirations into lucky 4D numbers!</div>
          </div>
        </div>

        <div className='componentDiv'>
          <div className='footerImage'>
            <img src={JackpotImg} alt='Jackpot' width='75px' height='75px' />
          </div>

          <div className='textPart'>
            <div className='titleFooterComponent'>Big Jackpot Winning</div>
            <div className='descriptionFooterComponent'>Find out where Jackpot winners bought their tickets.</div>
          </div>
        </div>

        <div className='componentDiv'>
          <div className='footerImage'>
            <img src={Coins} alt='Coins' width='75px' height='75px' />
          </div>

          <div className='textPart'>
            <div className='titleFooterComponent'>Did I Win?</div>
            <div className='descriptionFooterComponent'>Check if your numbers have won any prize.</div>
          </div>
        </div>

        <div className='componentDiv'>
          <div className='footerImage'>
            <img src={MobileApps} alt='Mobile' width='75px' height='75px' />
          </div>

          <div className='textPart'>
            <div className='titleFooterComponent'>Results on Mobile</div>
            <div className='descriptionFooterComponent'>Check if your numbers have won any prize.</div>
          </div>
        </div>
      </div>
    );
  }
}

export default footer;
