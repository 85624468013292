import React, { Component } from 'react';
import Sidebar from './components/sidebar';
import { withRouter } from 'react-router-dom';
import './css/aboutUs.css';
import ChangeLife from './elements/change-life.jpg';
import AllGames from './elements/all-games.png';
import FooterMenu from './components/footerMenu';

class aboutUs extends Component {
  constructor(props) {
    super(props);
    document.title = 'About Us | Poland Lotteries';
  }

  render() {
    return (
      <div>
        <Sidebar />

        <div className='contentContainer containerOnDesktop extraPadding'>
          <div className='titleByDayWrapper paddingAdjusment'>About Us</div>

          <div className='isiParagraf'>
            <img src={ChangeLife} alt='Change Life' />
            <h2>Together We're Changing Lives</h2>

            <p>
              It happens in dramatic, never-the-same-again ways. And in essential, life-is-a-little-better ways. We
              exist to change lives, to maximise returns to National Lottery Projects. But we couldn't do it without you
              – with every game you play, you make the UK better – play makes it possible.
            </p>
          </div>

          <div className='isiParagraf'>
            <h2>Life Changing Games</h2>

            <p>
              From instant grins to stunned gasps, our games create over eight million winners every week – not to
              mention, on average, six new millionaires. The National Lottery has so far given away over £69 billion in
              prizes and created more than 5,100 millionaires or multi-millionaires since its launch in 1994.
            </p>

            <p>
              The UK's first and favourite is Lotto. It's our national treasure that's been changing lives every week
              since 1994. EuroMillions is our international blockbuster – the big game with prizes to match. Our new
              game, Set For Life, has a top prize of £10,000 every month for 30 years. Instant Win Games bring the wins
              anytime, anywhere so there's never a dull moment. While Thunderball, Lotto HotPicks and EuroMillions
              HotPicks add extra winning flavour to each week.
            </p>
          </div>

          <div className='isiParagraf'>
            <img src={AllGames} alt='All Games' style={{ marginBottom: '10px' }} />
            <h2>Life Changing Projects</h2>

            <p>
              Around £30 million every week, on average. That's what players like you give to our projects. That's
              massive. You help build local communities, power sports teams, save the environment, unleash creative
              talent, empower the elderly and unlock young potential. Each week 400 new projects are supported. You're
              changing lives.
            </p>

            <p>
              Every part of the UK gets reached, averaging 190 projects per postcode area. That's over £39 billion,
              invested in the nation by 12 specialist organisations that ensure it goes exactly where it's needed.
            </p>
          </div>

          <div className='isiParagraf'>
            <h2>Change Life Responsibly</h2>

            <p>
              We're the nation's chance to dream. Our games are fun and exciting, and should always remain so. That's
              why player welfare, high standards and honesty are in our DNA.
            </p>

            <p>
              What does this mean to you? It means we always encourage Responsible Play. It means we always protect our
              players. It means lots of people playing a little, rather than a few playing a lot. We're all about
              changing lives for the better.
            </p>
          </div>
        </div>

        <FooterMenu />
      </div>
    );
  }
}

export default withRouter(aboutUs);
