import React from 'react';
import { withRouter } from 'react-router-dom';
import './css/login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };

    document.title = `Login | Poland Lotteries`;
  }

  checkLogin() {
    if (this.state.username === '' || this.state.password === '') {
      alert('Masukkan username dan password.');
    } else {
      fetch('/api/requestAccess', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
        }),
      })
        .then(response => {
          if (response.ok) {
            this.props.isAuth(true);
          } else if (response.status === 400) {
            alert('Username atau password salah. Silahkan cek kembali.');
          } else {
            alert('Terjadi kesalahan. Hubungi Webmaster.');
          }
        })
        .catch(err => {
          alert('Periksa kembali koneksi internet Anda dan coba lagi.');
          console.log(err);
        });
    }
  }

  valueSetter(ev) {
    this.setState({ [ev.target.name]: ev.target.value });
  }

  isSubmit(ev) {
    if (ev.charCode === 13) {
      this.checkLogin();
    }
  }

  render() {
    return (
      <div className='loginClass'>
        <div className='formBox'>
          <div className='formTitle'>RESTRICTED AREA</div>

          <div className='formWrapper'>
            <input
              tabindex='1'
              onKeyPress={this.isSubmit.bind(this)}
              name='username'
              onChange={this.valueSetter.bind(this)}
              placeholder='Username'
              type='username'
              className='usernameInput'
            />

            <input
              tabindex='2'
              onKeyPress={this.isSubmit.bind(this)}
              name='password'
              onChange={this.valueSetter.bind(this)}
              placeholder='Password'
              type='password'
              className='passwordInput'
              style={{ marginBottom: '20px' }}
            />

            <br />

            <button tabindex='3' className='submitLogin' onClick={this.checkLogin.bind(this)}>
              Go
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
